import { Link } from 'gatsby'
import React from 'react'

// Images 
import blogImg from '../../assets/images/blog/b1.jpg'
import blogImg2 from '../../assets/images/blog/b2.jpg'
import blogImg3 from '../../assets/images/blog/b3.jpg'

const BlogPostThree = () => {
    return (
        <>
            <div className="row justify-content-center pt-30">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="blog-card blog-card-2 mt-0">
                        <div className="blog-img-area">
                            <Link to="/blog-details">
                                <img 
                                    src={blogImg} 
                                    alt="blog"
                                />
                            </Link>
                            <div className="blog-img-date">
                                <span>19</span>
                                <span>Aug</span>
                            </div>
                        </div>
                        <div className="blog-text-area">
                            <div className="blog-date">
                                <ul>
                                    <li><i className="far fa-user-circle"></i> By <Link to="/author">Admin</Link> </li>
                                    <li><i className="far fa-comments"></i> 2 Comments</li>
                                </ul>
                            </div>
                            <h4><Link to="/blog-details">Digital Marketing: Staying Relevant To Empowered Consumer</Link></h4>
                            <Link className="default-button default-button-2" to="/blog-details">Read More <i className="fas fa-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="blog-card blog-card-2">
                        <div className="blog-img-area">
                            <Link to="/blog-details">
                                <img 
                                    src={blogImg2} 
                                    alt="blog"
                                />
                            </Link>
                            <div className="blog-img-date">
                                <span>18</span>
                                <span>Aug</span>
                            </div>
                        </div>
                        <div className="blog-text-area">
                            <div className="blog-date">
                                <ul>
                                    <li><i className="far fa-user-circle"></i> By <Link to="/author">Admin</Link> </li>
                                    <li><i className="far fa-comments"></i> 2 Comments</li>
                                </ul>
                            </div>
                            <h4><Link to="/blog-details">Grow Your Business With A Digital Marketing Strategy</Link></h4>
                            <Link className="default-button default-button-2" to="/blog-details">Read More <i className="fas fa-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="blog-card blog-card-2">
                        <div className="blog-img-area">
                            <Link to="/blog-details">
                                <img 
                                    src={blogImg3} 
                                    alt="blog"
                                />
                            </Link>
                            <div className="blog-img-date">
                                <span>17</span>
                                <span>Aug</span>
                            </div>
                        </div>
                        <div className="blog-text-area">
                            <div className="blog-date">
                                <ul>
                                    <li><i className="far fa-user-circle"></i> By <Link to="/author">Admin</Link> </li>
                                    <li><i className="far fa-comments"></i> 2 Comments</li>
                                </ul>
                            </div>
                            <h4><Link to="/blog-details">5 Inexpensive Online Tools To Get Your Business Off The Ground</Link></h4>
                            <Link className="default-button default-button-2" to="/blog-details">Read More <i className="fas fa-arrow-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="paginations mt-30">
                <ul>
                    <li className="active"><Link to="/blog-left-sidebar">1</Link></li>
                    <li><Link to="/blog-left-sidebar">2</Link></li>
                    <li><Link to="/blog-left-sidebar">3</Link></li>
                    <li><Link to="/blog-left-sidebar"><i className="fas fa-angle-right"></i></Link></li>
                </ul>
            </div>
        </>
    )
}

export default BlogPostThree
