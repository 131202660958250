import React from 'react'
import Layout from '../components/_App/Layout'
import Seo from '../components/_App/Seo'
import NavbarTwo from '../components/_App/NavbarTwo'
import PageBanner from '../components/Common/PageBanner'
import BlogSideBar from '../components/Blog/BlogSideBar'
import BlogPostThree from '../components/Blog/BlogPostThree'
import Footer from '../components/_App/Footer'
import Copyright from '../components/_App/Copyright'

const BlogRightPage = () => {
    return (
        <Layout>
            <Seo title="Blog Right Side" />

            <NavbarTwo />

            <PageBanner 
                pageTitle="Blog With Right Sidebar" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Blog With Right Sidebar" 
            />

            <div className="details-text-area ptb-100">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-8">
                            <BlogPostThree />
                        </div>

                        <div className="col-lg-4">
                            <div className="sidebar-area pl-20">
                                <BlogSideBar />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />

            <Copyright />
        </Layout>
    )
}

export default BlogRightPage
